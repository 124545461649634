@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;200;300;400&family=Roboto:wght@100;300;400');

@import url('https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope');

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    overflow-y: auto; 
}

body {
    background-image: url('./assets/images/univers.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.errorCard {
    background-image:url('./assets/images/deathStar.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.errorCard h1 {
    text-shadow: 1px 1px 2px red, 0 0 1em black, 0 0 0.2em black;
 }

.errorCard p {
    text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
}


@layer base {
    h1 {
        @apply text-2xl md:text-3xl sm:text-4xl;
    }
    h2 {
        @apply text-xl md:text-2xl sm:text-3xl;
    }
    h3 {
        @apply text-lg md:text-xl sm:text-2xl;
    }
    a {
        @apply no-underline;
    }
    label {
        @apply font-roboto text-center text-base md:text-lg text-div-blue font-semibold
    }
    input {
        @apply bg-green-200 border-2 border-div-blue rounded-md text-base sm:text-lg md:text-lg text-yellow-700
    }
    textarea {
        @apply bg-green-200 border-2 border-div-blue rounded-md text-base sm:text-lg md:text-lg text-yellow-700
    }
}

@layer components {
    .navBar {
        @apply bg-nav-greenOp w-full fixed z-50
    }
}